.rootmainfounder {
  width: auto;
  margin-bottom: 100px;

  .framebutton {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .buttoncategory-active {
      box-sizing: border-box;
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 28px;
      gap: 10px;
      width: 330px;
      height: 62px;
      /* BLACK 101010 */
      cursor: pointer;
      background: #101010;
      /* BLACK 101010 */

      border: 1px solid #101010;

      /* Inside auto layout */
      transition-duration: 550ms;
      transition-property: all;

      .detailtitlebutton {
        // width: 80px;
        width: auto;
        height: 30px;

        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */

        text-align: center;

        /* WHITE */

        color: #ffffff;
      }
    }
    .buttoncategory {
      box-sizing: border-box;
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 28px;
      gap: 10px;
      width: 330px;
      height: 62px;
      /* BLACK 101010 */
      cursor: pointer;
      background: white;
      /* BLACK 101010 */

      border: 1px solid #101010;

      .detailtitlebutton {
        width: auto;
        height: 30px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */

        text-align: center;

        /* BLACK 101010 */

        color: #101010;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
    .buttoncategory:hover {
      background-color: #f9f9f9;
    }
  }
  .framefounder {
    .frameimgfounder {
      transform: translateZ(0);
      position: relative;
      // margin-left: 12px;
      // margin-top: 10px;
      // width: 35%;

      border-radius: 40px;
      cursor: pointer;
      overflow: hidden;
      height: 100%;
      max-height: 664px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .imgfounder {
        // box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
        border-radius: 40px;
      }

      .framemaintile {
        width: 100%;
        .framedetailfoundername {
          display: flex;
          flex-direction: column;
          bottom: 5%;
          width: 100%;
          transition-property: all;
          transition-duration: 500ms;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          row-gap: 80px;
          z-index: 100;
          .detailfoundername {
            .titlename {
              font-family: "Poppins", sans-serif;
              font-style: normal;
              font-weight: 100;
              font-size: 50px;
              font-weight: 900;
              line-height: 50px;
              color: #ffffff;
              text-align: center;
              overflow-wrap: break-word;
              // margin: auto;
            }
          }
          .detailfoundername::after {
            content: "";
            display: block;
            width: 20%;
            height: 1px;
            background-color: white;
            position: relative;
            bottom: -40px;
            margin: auto;
          }
          .titledetail {
            /* Body 20 Poppins */
            // position: absolute;
            // top: 20;

            // background-color: red;
            width: 360px;
            height: 100px;
            margin: auto;
            // margin-top: 5%;
            // bottom: 5%;
            // left: 8%;
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 32px;
            /* or 160% */

            text-align: center;
            letter-spacing: -0.02em;

            /* WHITE */
            color: #ffffff;

            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            /* Inside auto layout */
            z-index: 60;
            transition-property: all;
            transition-duration: 500ms;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          }
        }
      }

      .bgBlur2 {
        width: 100%;
        top: 71%;
        height: 55%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        // transform: translateY(-100%);
        transition-property: all;
        transition-duration: 500ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      .btnInterviewfounder {
        position: absolute;
        left: 25%;
        transition-property: all;
        transition-duration: 500ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        z-index: 100;
      }
    }

    .frameimgfounder:hover {
      .bgBlur2 {
        transform: translateY(-44%);
        transition-property: all;
        transition-duration: 500ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      .framedetailfoundername {
        transform: translateY(-35%);
        transition-property: all;
        transition-duration: 500ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }

      .btnInterviewfounder {
        transform: translateY(-160%);
        transition-property: all;
        transition-duration: 500ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      img {
        transform: scale(110%, 110%);
        transition-property: all;
        transition-duration: 500ms;

        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }
}

// RESPONSIVE

// LANDSCAPE

@media screen and (max-width: 1279px) and (orientation: landscape) {
  .rootmainfounder {
    width: auto;

    .carouselcategory {
      // height: auto;
      // width: 100%;
      height: 24px;
      margin: auto;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      padding-left: 35px;
      letter-spacing: -0.5px;

      /* Gray/800 */

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;

      .slick-list {
        // width: 522px;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .slick-slide {
        width: fit-content !important;
      }
      .tab-active {
        .itemslide {
          font-weight: 600;
          background: #ffffff;
          width: max-content;
          padding-top: 17px;
          color: #222222;
          text-align: center;
        }
        .itemslide::after {
          content: "";
          display: block;
          width: 100px;
          height: 2px;
          margin: 0;
          margin-top: 15px;
          background: #222222;
        }
      }
      .itemslide {
        color: #999999;
        background: #ffffff;
        width: max-content;
        padding-top: 17px;
        text-align: center;
      }
      .itemslide:hover {
        cursor: pointer;
        color: #222222;
      }
    }
    // IMG FOUNDER

    .framefounder {
      .frameimgfounder {
        position: relative;
        cursor: pointer;
        margin-top: 10px;
        // margin-left: 20px;
        border-radius: 18px;
        max-height: auto;
        img {
          height: auto;
        }
        .imgfounder {
          border-radius: 18px;
        }
        .framemaintile {
          width: 100%;
          .framedetailfoundername {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            bottom: 7%;

            border-radius: 40px;
            background-color: transparent;
            .detailfoundername {
              flex: none;
              order: 0;
              flex-grow: 0;
              .titlename {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                color: #ffffff;
                text-align: center;
                font-size: 24px;
                line-height: 24px;
                font-weight: 900;
                overflow-wrap: break-word;
                width: 120px;
              }
            }
            .detailfoundername::after {
              display: none;
            }
            .titledetail {
              display: none;
            }
          }
        }
      }
      .frameimgfounder:hover {
        .bgBlur2 {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .framedetailfoundername {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .titledetail {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .btnInterviewfounder {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        img {
          transform: none;
        }
      }
    }
  }
}

/* MOBILE WIDTH < 639px */
@media screen and (max-width: 639px) {
  .rootmainfounder {
    width: auto;
    .carouselcategory {
      // height: auto;
      height: 24px;

      // margin: auto;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      letter-spacing: -0.5px;
      // padding-left: 35px;
      // background-color: red;
      .slick-list {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 35px;
        // background-color: aqua;
      }
      .slick-slide {
        width: fit-content !important;
      }
      .tab-active {
        .itemslide {
          font-weight: 600;
          // background: #ffffff;
          width: max-content;
          padding-top: 17px;
          color: #222222;
          text-align: center;
        }
        .itemslide::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          margin: 0px;
          margin-top: 15px;
          background: #222222;
        }
      }
      .itemslide {
        color: #999999;
        background: #ffffff;
        width: max-content;
        padding-top: 17px;
        text-align: center;
      }

      .itemslide:hover {
        cursor: pointer;
        color: #222222;
      }
    }
    // IMG FOUNDER

    .framefounder {
      .frameimgfounder {
        position: relative;
        cursor: pointer;
        margin-top: 10px;
        // margin-left: 20px;
        border-radius: 18px;
        max-height: 225.5px;
        .imgfounder {
          border-radius: 18px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .framemaintile {
          width: 100%;
          .framedetailfoundername {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            bottom: 7%;

            border-radius: 40px;
            background-color: transparent;
            width: 100%;

            .detailfoundername {
              .titlename {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                color: #ffffff;
                text-align: center;
                font-size: 24px;
                line-height: 24px;
                font-weight: 900;
                overflow-wrap: break-word;
                width: 120px;
              }
            }
            .detailfoundername::after {
              display: none;
            }
            .titledetail {
              display: none;
            }
          }
        }
      }
      .frameimgfounder:hover {
        .bgBlur2 {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .framedetailfoundername {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .titledetail {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .btnInterviewfounder {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        img {
          transform: none;
        }
      }
    }
  }
}

/* TABLET WIDTH >=640px and width < 767px and (orientation: landscape)*/
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .rootmainfounder {
    width: auto;
    .framebutton {
      .buttoncategory-active {
        width: 150px;
        height: 62px;
        .detailtitlebutton {
          // width: 80px;
          width: auto;
          height: 30px;
          font-weight: 600;
          font-size: 15px;
          line-height: 30px;
        }
      }
      .buttoncategory {
        width: 150px;
        height: 62px;
        .detailtitlebutton {
          width: auto;
          height: 30px;
          font-size: 15px;
          line-height: 30px;
        }
      }
    }
    .framefounder {
      .frameimgfounder {
        position: relative;
        cursor: pointer;
        margin-top: 10px;
        // margin-left: 20px;
        border-radius: 18px;
        max-height: 225.5px;
        .imgfounder {
          border-radius: 18px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .imgfounder {
          border-radius: 0px;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
        }
        .framemaintile {
          width: 100%;
          .framedetailfoundername {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            bottom: 7%;

            border-radius: 40px;
            background-color: transparent;
            .detailfoundername {
              flex: none;
              order: 0;
              flex-grow: 0;
              .titlename {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                color: #ffffff;
                text-align: center;
                font-size: 24px;
                line-height: 24px;
                font-weight: 900;
                overflow-wrap: break-word;
                width: 120px;
              }
            }
            .detailfoundername::after {
              display: none;
            }
            .titledetail {
              display: none;
            }
          }
        }
      }
      .frameimgfounder:hover {
        .bgBlur2 {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .framedetailfoundername {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .titledetail {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .btnInterviewfounder {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        img {
          transform: none;
        }
      }
    }
  }
}
/* TABLET WIDTH >=768px and width < 1023px*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .rootmainfounder {
    width: auto;
    .framebutton {
      .buttoncategory-active {
        width: 180px;
        height: 62px;
        .detailtitlebutton {
          // width: 80px;
          width: auto;
          height: 30px;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
        }
      }
      .buttoncategory {
        width: 180px;
        height: 62px;
        .detailtitlebutton {
          width: auto;
          height: 30px;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    .framefounder {
      .frameimgfounder {
        position: relative;
        cursor: pointer;
        margin-top: 10px;
        // margin-left: 20px;
        border-radius: 18px;
        max-height: 225.5px;
        .imgfounder {
          border-radius: 18px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .imgfounder {
          border-radius: 0px;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
        }
        .framemaintile {
          width: 100%;
          .framedetailfoundername {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            bottom: 7%;

            border-radius: 40px;
            background-color: transparent;
            .detailfoundername {
              flex: none;
              order: 0;
              flex-grow: 0;
              .titlename {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                color: #ffffff;
                text-align: center;
                font-size: 24px;
                line-height: 24px;
                font-weight: 900;
                overflow-wrap: break-word;
                width: 120px;
              }
            }
            .detailfoundername::after {
              display: none;
            }
            .titledetail {
              display: none;
            }
          }
        }
      }
      .frameimgfounder:hover {
        .bgBlur2 {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .framedetailfoundername {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .titledetail {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .btnInterviewfounder {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        img {
          transform: none;
        }
      }
    }
  }
}
/* TABLET WIDTH >=1024px and width < 1279px*/
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .rootmainfounder {
    width: auto;
    .framebutton {
      .buttoncategory-active {
        width: 250px;
        height: 62px;
        .detailtitlebutton {
          // width: 80px;
          width: auto;
          height: 30px;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
        }
      }
      .buttoncategory {
        width: 250px;
        height: 62px;
        .detailtitlebutton {
          width: auto;
          height: 30px;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    .framefounder {
      .frameimgfounder {
        position: relative;
        cursor: pointer;
        margin-top: 10px;
        // margin-left: 20px;
        border-radius: 18px;
        max-height: 225.5px;
        .imgfounder {
          border-radius: 18px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .imgfounder {
          border-radius: 0px;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
        }
        .framemaintile {
          width: 100%;
          .framedetailfoundername {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            bottom: 7%;
            border-radius: 40px;
            background-color: transparent;
            .detailfoundername {
              flex: none;
              order: 0;
              flex-grow: 0;
              .titlename {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                color: #ffffff;
                text-align: center;
                font-size: 24px;
                line-height: 24px;
                font-weight: 900;
                overflow-wrap: break-word;
              }
            }
            .detailfoundername::after {
              display: none;
            }
            .titledetail {
              display: none;
            }
          }
        }
      }
      .frameimgfounder:hover {
        .bgBlur2 {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .framedetailfoundername {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .titledetail {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        .btnInterviewfounder {
          transform: translateY(0%);
          transition-property: none;
          transition-duration: 200ms;
        }
        img {
          transform: none;
        }
      }
    }
  }
}
/* PC WIDTH >=1200px and width < 1535px*/
@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  .rootmainfounder {
    width: auto;
    .framebutton {
      margin-top: 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .buttoncategory-active {
        box-sizing: border-box;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 28px;
        gap: 10px;
        width: 250px;
        height: 62px;
        /* BLACK 101010 */
        cursor: pointer;
        background: #101010;
        /* BLACK 101010 */

        border: 1px solid #101010;

        /* Inside auto layout */
        transition-duration: 550ms;
        transition-property: all;

        .detailtitlebutton {
          // width: 80px;
          width: auto;
          height: 30px;
        }
      }
      .buttoncategory {
        padding: 16px 28px;
        gap: 10px;
        width: 250px;
        height: 62px;

        .detailtitlebutton {
          width: auto;
          height: 30px;
        }
      }
      .buttoncategory:hover {
        background-color: #f9f9f9;
      }
    }
    .framefounder {
      .frameimgfounder {
        position: relative;
        // margin-left: 12px;
        // margin-top: 10px;
        //   width: 400px;
        border-radius: 40px;
        cursor: pointer;
        overflow: hidden;
        max-height: 664px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .bgBlur2 {
          width: 100%;
          top: 71%;
          height: 55%;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          // transform: translateY(-100%);
        }
      }
    }
  }
}
// /* PC WIDTH >=1536px and width < 1600px*/
@media only screen and (min-width: 1536px) and (max-width: 1599px) {
  .rootmainfounder {
    width: 100%;
    .framebutton {
      margin-top: 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .buttoncategory-active {
        box-sizing: border-box;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 28px;
        gap: 10px;
        width: 330px;
        height: 62px;
        /* BLACK 101010 */
        cursor: pointer;
        background: #101010;
        /* BLACK 101010 */

        border: 1px solid #101010;

        /* Inside auto layout */
        transition-duration: 550ms;
        transition-property: all;

        .detailtitlebutton {
          // width: 80px;
          width: auto;
          height: 30px;
        }
      }
      .buttoncategory {
        padding: 16px 28px;
        gap: 10px;
        width: 330px;
        height: 62px;

        .detailtitlebutton {
          width: auto;
          height: 30px;
        }
      }
      .buttoncategory:hover {
        background-color: #f9f9f9;
      }
    }
    .framefounder {
      .frameimgfounder {
        position: relative;
        // margin-left: 12px;
        // margin-top: 10px;
        //   width: 400px;
        border-radius: 40px;
        cursor: pointer;
        overflow: hidden;
        max-height: 664px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .bgBlur2 {
          width: 100%;
          top: 71%;
          height: 55%;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          // transform: translateY(-100%);
        }
      }
      .btnInterviewfounder:hover {
        .bgBlur2 {
          transform: translateY(-44%);
          transition-property: all;
          transition-duration: 200ms;
        }
        .framedetailfoundername {
          transform: translateY(-140%);
          transition-property: all;
          transition-duration: 200ms;
        }
        .titledetail {
          transform: translateY(-140%);
          transition-property: all;
          transition-duration: 200ms;
        }
        .btnInterviewfounder {
          transform: translateY(-200%);
          transition-property: all;
          transition-duration: 200ms;
        }
        img {
          transform: scale(110%, 110%);
          transition-property: all;
          transition-duration: 150ms;
        }
      }
    }
  }
}
// /* PC WIDTH >=1536px and width < 1600px*/
@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .rootmainfounder {
    width: 100%;

    .framefounder {
      .frameimgfounder {
        position: relative;
        // margin-left: 12px;
        // margin-top: 10px;
        //   width: 400px;
        border-radius: 40px;
        cursor: pointer;
        overflow: hidden;
        max-height: 664px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .bgBlur2 {
          width: 100%;
          top: 71%;
          height: 55%;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          // transform: translateY(-100%);
        }
      }
    }
  }
}
