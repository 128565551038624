.framenew {
  width: 1320px;
  margin: auto;
  margin-top: 150px;
  margin-bottom: 150px;
  .framenewtitle {
    width: auto;
    height: 60px;
    margin: auto;
    text-align: center;
    /* H2 */
    font-family: "Poppins", sans-serif;
    font-style: italic;
    font-weight: 900;
    font-size: 60px;
    line-height: 60px;
    /* identical to box height, or 100% */

    letter-spacing: -0.01em;
    color: #101010;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    .viewtitle {
      display: none;
    }
  }
  .framedetailnewmain {
    display: flex;
    margin-top: 100px;
    column-gap: 20px;
    .framedetail {
      display: flex;

      flex-direction: column;
      width: 441px;
      height: 401px;
      text-align: left;
      border-bottom: 1px solid #101010;

      :nth-child(2) {
        color: red;
        margin-top: 15px;
        font-family: "Roboto Flex", sans-serif;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        color: #db2f33;
        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }
      :nth-child(3) {
        margin-top: 15px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        /* or 142% */

        letter-spacing: -0.01em;
        /* BLACK */
        color: #101010;
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      :nth-child(3):hover {
        cursor: pointer;
        text-decoration: underline black 2px;
      }
      :nth-child(4) {
        margin-top: 15px;
        font-family: "Roboto Flex", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */
        /* BLACK */
        color: #101010;
        opacity: 0.8;
        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

/* MOBILE WIDTH < 740px */
@media screen and (max-width: 769px) {
  .framenew {
    width: 100%;
    margin: auto;
    margin-top: 120px;
    .framenewtitle {
      display: flex;
      height: 44px;
      width: 100%;
      margin: auto;
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;
      /* H2 */
      font-size: 40px;
      line-height: 44px;
      /* identical to box height, or 100% */

      letter-spacing: -0.01em;
      color: #101010;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      .viewtitle {
        display: block;
        width: 68px;
        height: 24px;
        font-family: "Roboto Flex", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */
        margin-left: auto;
        margin-top: 12px;
        /* BLACK */
        border-bottom: 1px solid #101010;
        color: #101010;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
    .framedetailnewmain {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      column-gap: 0px;
      padding-left: 35px;
      padding-right: 35px;
      margin-bottom: 70px;

      .framedetail {
        display: flex;
        margin: auto;
        flex-direction: column;
        width: 100%;
        height: auto;
        text-align: left;
        margin-bottom: 30px;
        .frameimgnew {
          width: 100%;
          // img {
          //   width: 100%;
          //   // height: auto;
          // }
        }

        :nth-child(2) {
          color: red;
          margin-top: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */

          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 0;
        }
        :nth-child(3) {
          width: 100%;
          margin-top: 10px;
          font-size: 18px;
          /* or 142% */
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        :nth-child(3):hover {
          cursor: pointer;
          text-decoration: underline black 2px;
        }
        :nth-child(4) {
          width: 100%;
          margin-top: 10px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

/* PC WIDTH >=1280px and width < 1535px*/
@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  .framenew {
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 150px;
    .framenewtitle {
      width: auto;
      height: 60px;
      margin: auto;
      text-align: center;
      /* H2 */

      .viewtitle {
        display: none;
      }
    }
    .framedetailnewmain {
      display: flex;
      margin: auto;
      margin-top: 100px;
      column-gap: 20px;
      width: 80%;
      .framedetail {
        display: flex;

        flex-direction: column;
        width: 334px;
        height: 355px;
        text-align: left;
        border-bottom: 1px solid #101010;

        :nth-child(2) {
          color: red;
          margin-top: 15px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */

          color: #db2f33;
          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 0;
        }
        :nth-child(3) {
          margin-top: 15px;

          font-size: 24px;
          line-height: 34px;
        }
        :nth-child(3):hover {
          cursor: pointer;
          text-decoration: underline black 2px;
        }
        :nth-child(4) {
          margin-top: 15px;

          font-size: 16px;
          line-height: 24px;
          /* or 150% */
          /* BLACK */
        }
      }
    }
  }
}

/* PC WIDTH >=1536px and width < 1600px*/
@media only screen and (min-width: 1536px) and (max-width: 1600px) {
  .framenew {
    width: 1320px;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 150px;
    .framenewtitle {
      width: auto;
      height: 60px;
      margin: auto;
      text-align: center;
      /* H2 */
      font-family: "Poppins", sans-serif;
      font-style: italic;
      font-weight: 900;
      font-size: 60px;
      line-height: 60px;
      /* identical to box height, or 100% */

      letter-spacing: -0.01em;
      color: #101010;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      .viewtitle {
        display: none;
      }
    }
    .framedetailnewmain {
      display: flex;
      margin-top: 100px;
      column-gap: 20px;
      .framedetail {
        display: flex;

        flex-direction: column;
        width: 441px;
        height: 401px;
        text-align: left;
        border-bottom: 1px solid #101010;

        :nth-child(2) {
          color: red;
          margin-top: 15px;
          font-family: "Roboto Flex", sans-serif;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */

          color: #db2f33;
          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 0;
        }
        :nth-child(3) {
          margin-top: 15px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 34px;
          /* or 142% */

          letter-spacing: -0.01em;
          /* BLACK */
          color: #101010;
          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        :nth-child(3):hover {
          cursor: pointer;
          text-decoration: underline black 2px;
        }
        :nth-child(4) {
          margin-top: 15px;
          font-family: "Roboto Flex", sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          /* or 150% */
          /* BLACK */
          color: #101010;
          opacity: 0.8;
          /* Inside auto layout */
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
