.frameheadermain {
  display: flex;
  position: relative;
  width: auto;
  // height: auto;
  .frameimglayout {
    position: relative;
    width: 100%;
    .logobackgroundheader {
      width: 100%;
    }
  }
  .frameimglayout::before {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .imglogotrauframe-header {
    position: absolute;
    left: 90px;
    right: 90px;
    top: 2px;
    bottom: 17.85%;
    padding-top: 20px;
    height: fit-content;
    width: fit-content;
  }
  .titledetaimainframeheader {
    position: absolute;
    display: flex;
    // left: 75.8%;
    right: 90px;
    top: 0%;
    bottom: 0%;
    padding-top: 28px;
    height: fit-content;
    .titledetaiframeheader {
      cursor: pointer;
      margin-left: 48px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      /* WHITE */
      color: #ffffff;
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .titledetaiframeheader:hover {
      color: #828383;
    }
    .titledetaiframeheader-active {
      margin-left: 48px;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      /* WHITE */

      color: #ffffff;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .titledetaiframeheader-active:hover {
      color: #828383;
    }
  }

  .framemaintilecenterheader {
    position: absolute;
    width: auto;
    height: auto;
    left: 50%;
    top: 55%;
    transform: translateX(-50%) translateY(-55%);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    /* or 117% */
    text-align: center;
    letter-spacing: -0.01em;
    /* WHITE */
    color: #ffffff;
    .framedetailtitlemain {
      font-style: italic;
      font-weight: 100;
    }
  }
}

// RESPONSIVE

/* MOBILE WIDTH < 639px */
@media screen and (max-width: 639px) {
  .frameheadermain {
    position: relative;
    display: flex;
    width: 100%;
    height: 390px;
    .frameimglayout {
      width: 100%;
      .logobackgroundheader {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
    }

    .imglogotrauframe-header {
      position: absolute;
      left: 0%;
      right: 81.74%;
      top: 0%;
      bottom: 0%;
      padding-top: 18.98px;
      padding-left: 20px;
      width: 100px !important;
      img {
        width: 100px !important;
        // max-width: 100px !important;
      }
    }
    .titledetaimainframeheader {
      position: absolute;
      left: 85%;
      font-size: 30px;
      padding-top: 15px;

      // background-color: red;
    }

    .framemaintilecenterheader {
      position: absolute;
      width: 284px;
      height: auto;
      left: 10%;
      top: 70%;
      transform: translateY(-50%);
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 44px;
      /* or 110% */
      letter-spacing: -0.01em;

      color: #ffffff;
      text-align: left;
      .framedetailtitlemain {
        font-style: italic;
        font-weight: 100;
      }
    }
  }
}

/* TABLET WIDTH >=640px and width < 767px*/
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .frameheadermain {
    .imglogotrauframe-header {
      position: absolute;
      left: 50px;
      right: 0%;
      top: -0.07%;
      bottom: 17.85%;
      padding-top: 20px;
    }
    .titledetaimainframeheader {
      position: absolute;
      display: flex;
      // left: 75.8%;
      right: 50px;
      top: 0%;
      bottom: 0%;
      padding-top: 28px;
      height: fit-content;
      .titledetaiframeheader {
        cursor: pointer;
        margin-left: 30px;
        font-size: 16px;
        line-height: 24px;
      }
      .titledetaiframeheader-active {
        margin-left: 30px;
        font-size: 16px;
        line-height: 24px;
      }
      .titledetaiframeheader-active:hover {
        color: #828383;
      }
    }
    .framemaintilecenterheader {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

/* TABLET WIDTH >=740px and width < 1024px*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .frameheadermain {
    .imglogotrauframe-header {
      position: absolute;
      left: 50px;
      right: 0%;
      top: -0.07%;
      bottom: 17.85%;
      padding-top: 20px;
    }
    .titledetaimainframeheader {
      position: absolute;
      display: flex;
      // left: 75.8%;
      right: 50px;
      top: 0%;
      bottom: 0%;
      padding-top: 28px;
      height: fit-content;

      .titledetaiframeheader:hover {
        color: #828383;
      }
      .titledetaiframeheader-active {
        line-height: 24px;
      }
    }
    .framemaintilecenterheader {
      font-size: 30px;
      line-height: 30px;
    }
  }
}
/* Laptop WIDTH >=1023px and width < 1279px*/
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .frameheadermain {
    .imglogotrauframe-header {
      position: absolute;
      left: 50px;
      right: 0%;
      top: -0.07%;
      bottom: 17.85%;
      padding-top: 20px;
    }
    .titledetaimainframeheader {
      position: absolute;
      display: flex;
      // left: 75.8%;
      right: 50px;
      top: 0%;
      bottom: 0%;
      padding-top: 28px;
      height: fit-content;

      .titledetaiframeheader:hover {
        color: #828383;
      }
      .titledetaiframeheader-active {
        line-height: 24px;
      }
    }
    .framemaintilecenterheader {
      font-size: 30px;
      line-height: 30px;
    }
  }
}

/* PC WIDTH >=1280px and width < 1535px*/
@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  .frameheadermain {
    .framemaintilecenterheader {
      font-size: 50px;
      line-height: 50px;
    }
  }
}
