.ant-btn-primary {
  color: #fff;
  border: none !important;
  background: transparent;
  box-shadow: none !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: transparent !important;
  border: none !important;
}
html {
  --antd-wave-shadow-color: transparent;
  --scroll-bar: 0;
}

.ant-modal-header {
  display: none !important;
}

.ant-modal-close-x {
  display: none !important;
}

.ant-modal-footer {
  display: none !important;
}

.ant-modal {
  margin: 0 !important;
}

a {
  color: black;
}

.ant-modal-body {
  height: 100vh;
}
