.ant-collapse-content {
  border-top: none;
}
.ant-collapse {
  border: none;
  border-radius: 0;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none;
  background-color: white;
}

.greyBg {
  background-color: #f7f8f9;
  /* background-color: red; */
}

.ant-collapse-header-text {
  width: 100%;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}

.ant-collapse-expand-icon {
  position: absolute;
  left: 96%;
  top: 42%;
}

@media only screen and (min-width: 0) and (max-width: 425px) {
  .mText {
    font-size: 18px !important;
  }
  .ant-collapse-expand-icon {
    left: 91%;
    top: 19%;
  }
}
