#detailpage-investment {
  .red-text {
    font-family: 'Roboto Flex';
    font-style: italic;
    font-weight: 800;
    font-size: 18px;
    line-height: 48px;
    color: #DB2F33;
  }

  #td-year, #td-round, #td-investor, #td-year-2, #td-round-2, #td-investor-2 {
    vertical-align: unset;
  }
  .investment .investment-content-mobile {
    display: none;
  }

  .investment {
    padding-top: 140px;
    margin-bottom: 180px;
  }

  .dot {
    width: 15px;
    height: 60px;

    font-family: "Poppins";
    font-style: italic;
    font-weight: 900;
    font-size: 60px;
    line-height: 60px;

    letter-spacing: -0.01em;

    color: #db2f33;
  }

  .investment-title .inv-text {
    height: 60px;

    font-family: "Poppins";
    font-style: italic;
    font-weight: 900;
    font-size: 60px;
    line-height: 60px;

    letter-spacing: -0.01em;

    color: #101010;
  }

  .investment-title {
    margin-bottom: 80px;
    height: 60px;
  }

  .invesment-content-mobile {
    display: none;
  }

  .investment .investment-content {
    display: flex;
  }

  .investment .investment-content .year {
    width: 160px;
    height: 181px;
    margin-right: 58px;
  }

  .investment .investment-content .round {
    width: 160px;
    height: 181px;
    margin-right: 63.5px;
  }

  .investment .investment-content .investor {
    width: 880px;
    height: 181px;
  }

  .investment .investment-content .column-name {
    margin-bottom: 13px;
  }
  .investment .investment-content .column-name p {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #db2f33;
  }

  .investment .investment-content .column-value {
    height: 144px;
  }

  .investment .investment-content .column-value p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 200%;
    /* or 48px */

    letter-spacing: -0.01em;
  }

  .investment .investment-content .investor .column-value p {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 48px;
    /* or 267% */

    /* BLACK */

    color: #101010;

    opacity: 0.8;
  }

  .investment .investment-content .year .column-value {
    border-right: 1px solid #000000;
  }

  .investment .investment-content .round .column-value {
    border-right: 1px solid #000000;
  }

  // --------------------------------------1023px

  @media only screen and (max-width: 1023px) {
    .investment {
      padding-top: 100px;
      margin-bottom: 100px;
    }
    .investment-title {
      width: 100%;
      margin-bottom: 40px;
    }

    .investment-title .inv-text {
      height: 44px;

      /* m_H1 Black Italic */

      font-family: "Poppins";
      font-style: italic;
      font-weight: 900;
      font-size: 40px;
      line-height: 44px;
      /* identical to box height, or 110% */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #101010;
    }

    .dot {
      width: 255px;
      height: 44px;

      /* m_H1 Black Italic */

      font-family: "Poppins";
      font-style: italic;
      font-weight: 900;
      font-size: 40px;
      line-height: 44px;
      /* identical to box height, or 110% */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #db2f33;
    }

    .investment .investment-content {
      display: none;
    }

    .investment .investment-content-mobile {
      display: block;
    }

    .investment-content-mobile .in-content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
      border-left: 1px solid #000000;
      padding-left: 23px;
    }

    .investment-content-mobile .in-content1 {
      width: 120px;
      height: 52px;
      margin-right: 24px;
      margin-bottom: 12px;
    }
    .investment-content-mobile .in-content2 {
      width: 120px;
      height: 52px;
      margin-bottom: 12px;
    }

    .investment-content-mobile .in-content3 {
      width: 264px;
      height: 80px;
      margin-bottom: 12px;
    }

    .investment-content-mobile .in-content1 .in-content-name {
      margin-bottom: 2px;
    }

    .investment-content-mobile .in-content2 .in-content-name {
      margin-bottom: 2px;
    }

    .investment-content-mobile .in-content3 .in-content-name {
      margin-bottom: 2px;
    }

    .investment-content-mobile .in-content1 .in-content-name p {
      font-family: "Poppins";
      font-style: italic;
      font-weight: 800;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      color: #db2f33;
    }

    .investment-content-mobile .in-content2 .in-content-name p {
      font-family: "Poppins";
      font-style: italic;
      font-weight: 800;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      color: #db2f33;
    }

    .investment-content-mobile .in-content3 .in-content-name p {
      font-family: "Poppins";
      font-style: italic;
      font-weight: 800;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      color: #db2f33;
    }

    .investment-content-mobile .in-content1 .in-content-value {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 200%;
      /* identical to box height, or 26px */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #101010;
    }

    .investment-content-mobile .in-content2 .in-content-value {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 200%;
      /* identical to box height, or 26px */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #101010;
    }

    .investment-content-mobile .in-content3 .in-content-value p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
      /* or 138% */

      /* BLACK */

      color: #101010;
    }
  }
}

.inv-border::before {
  content: "";
  width: 1px;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  height: 90%;
  background-color: #000000;
  left: 123px;
}

.inv-border::after {
  content: "";
  top: 50%;
  transform: translate(0, -50%);
  width: 1px;
  position: absolute;
  height: 90%;
  background-color: #000000;
  left: 306px;
}

tr.spaceUnder > td {
  padding-bottom: 1rem;
  // border-right: 1px solid black;
}

.spaceUnder > .borderCol {
  position: relative;
}

.spaceUnder > .borderCol::after {
  z-index: 100;
  content: "";
  position: absolute;
  width: 1px;
  background-color: #000000;
  height: 140%;
  top: 0;
}

.spaceUnder > .firstBorderCol {
  position: relative;
}

.spaceUnder > .firstBorderCol::after {
  z-index: 100;
  content: "";
  position: absolute;
  width: 1px;
  background-color: #000000;
  height: 100%;
  bottom: 0;
  top: 20px;
}

.spaceUnder > .lastBorderCol {
  position: relative;
}

.spaceUnder > .lastBorderCol::after {
  z-index: 100;
  content: "";
  position: absolute;
  width: 1px;
  background-color: #000000;
  height: 100%;
  // top: -30px;
  bottom: 30px;
}

.oneItemBorderCol {
  position: relative;
}

.oneItemBorderCol::after {
  z-index: 100;
  content: "";
  position: absolute;
  width: 1px;
  background-color: #000000;
  height: 60%;
  top: 52%;
  transform: translate(0, -50%);
}

// .spaceUnder > .borderCol:first-child::after {
//   visibility: hidden;
// }
