.frameheaderfoundermain {
  display: flex;
  position: relative;
  width: 100%;
  .frameimglayout {
    position: relative;
    width: 100%;
    // max-height: 554px;
    // height: 50vh;

    .logobackgroundheader {
      width: 100%;
      max-height: 640px;
      object-fit: cover;
    }
  }

  .imglogotrauframe-header {
    position: absolute;
    left: 5%;
    right: 0%;
    top: -0.07%;
    bottom: 17.85%;
    padding-top: 20px;
    height: fit-content;
    width: fit-content;
  }
  .titledetaimainframeheader {
    position: absolute;
    display: flex;
    // display: -webkit-flex;
    // left: 75.8%;
    right: 5%;
    top: 0%;
    bottom: 0%;
    padding-top: 28px;
    height: auto;
    column-gap: 2.6vw;
    // -webkit-column-gap: 2.6vw;
    .titledetaiframeheader {
      cursor: pointer;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      /* WHITE */
      color: #ffffff;
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .titledetaiframeheader-active {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      /* WHITE */

      color: #ffffff;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .titledetaiframeheader-active:hover {
      color: #828383;
    }
    .titledetaiframeheader:hover {
      color: #828383;
    }
  }
  .framemaintilecenterheader {
    position: absolute;
    width: auto;
    height: auto;
    left: 15.5%;
    top: 29%;
    // transform: translateX(-22%);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 100;
    // font-size: 5.25vw;
    // font-size: 5.25vw;
    font-size: 100px;
    // line-height: 100px;
    line-height: 100px;
    /* or 117% */
    text-align: left;
    letter-spacing: -0.01em;
    /* WHITE */
    color: #ffffff;
    .framedetailtitlemain {
      font-style: normal;
      font-weight: 700;
    }
    .framedetailtitlemain::after {
      content: "";
      display: block;
      width: 43px;
      height: 2px;
      margin-top: 8.5%;
      margin-left: 5px;
      background: #fcfcfc;
    }
    .titlecompany {
      width: 500px;
      margin-top: 7%;
      font-style: normal;
      font-weight: 600;
      // font-size: 1vw;
      font-size: 20px;
      margin-left: 5px;
      line-height: 32%;
      // line-height: 170%;
      /* identical to box height, or 160% */
      letter-spacing: -0.02em;
      color: #ffffff;
    }
  }
}

// RESPONSIVE

/* MOBILE WIDTH < 639px */
@media screen and (max-width: 639px) {
  .frameheaderfoundermain {
    position: relative;
    display: flex;
    width: 100%;
    height: 390px;
    z-index: 1000;
    .frameimglayout {
      position: relative;
      width: 100%;
      .logobackgroundheader {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
    }

    .imglogotrauframe-header {
      position: absolute;
      left: 0%;
      right: 81.74%;
      top: 0%;
      bottom: 17.85%;
      padding-top: 18.98px;
      padding-left: 20px;
      width: 117px;
      img {
        width: 100px;
      }
    }
    .titledetaimainframeheader {
      position: absolute;
      left: 85%;
      font-size: 30px;
      padding-top: 15px;
    }
    .framemaintilecenterheader {
      position: absolute;
      width: auto;
      height: auto;
      top: 53%;
      left: 10%;
      transform: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 100;
      font-size: 40px;
      line-height: 44px;
      /* or 117% */
      text-align: left;
      letter-spacing: -0.01em;
      /* WHITE */
      color: #ffffff;
      .framedetailtitlemain {
        font-style: normal;
        font-weight: 700;
      }
      .framedetailtitlemain::after {
        content: "";
        display: block;
        width: 32px;
        height: 2px;
        margin-top: 15px;
        margin-left: 5px;
        background: #fcfcfc;
      }
      .titlecompany {
        width: auto;
        height: 20px;
        margin-top: 15px;
        font-style: normal;
        font-weight: 600;
        margin-left: 5px;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 160% */
        letter-spacing: -0.02em;
        color: #ffffff;
      }
    }
  }
}
/*TABLET  WIDTH >=640px and width < 768px*/
@media only screen and (min-width: 640px) and (max-width: 768px) {
  .frameheaderfoundermain {
    .framemaintilecenterheader {
      font-size: 40px;
      // line-height: 100px;
      line-height: 40px;

      .framedetailtitlemain {
        font-style: normal;
        font-weight: 700;
      }
      .framedetailtitlemain::after {
        content: "";
        display: block;
        width: 43px;
        height: 2px;
        margin-top: 4.5%;
        margin-left: 5px;
        background: #fcfcfc;
      }
      .titlecompany {
        width: 500px;
        margin-top: 4%;
        font-style: normal;
        font-weight: 600;
        // font-size: 1vw;
        font-size: 13px;
        margin-left: 5px;
        line-height: 32%;
        // line-height: 170%;
        /* identical to box height, or 160% */
        letter-spacing: -0.02em;
        color: #ffffff;
      }
    }
  }
}
/*TABLET  WIDTH >=768px and width < 1023px*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .frameheaderfoundermain {
    .framemaintilecenterheader {
      font-size: 40px;
      // line-height: 100px;
      line-height: 40px;

      .framedetailtitlemain {
        font-style: normal;
        font-weight: 700;
      }
      .framedetailtitlemain::after {
        content: "";
        display: block;
        width: 43px;
        height: 2px;
        margin-top: 6.5%;
        margin-left: 5px;
        background: #fcfcfc;
      }
      .titlecompany {
        width: 500px;
        margin-top: 6%;
        font-style: normal;
        font-weight: 600;
        // font-size: 1vw;
        font-size: 13px;
        margin-left: 5px;
        line-height: 32%;
        // line-height: 170%;
        /* identical to box height, or 160% */
        letter-spacing: -0.02em;
        color: #ffffff;
      }
    }
  }
}

/*TABLET  WIDTH >=1024px and width < 1279px*/
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .frameheaderfoundermain {
    .framemaintilecenterheader {
      font-size: 60px;
      // line-height: 100px;
      line-height: 60px;

      .framedetailtitlemain {
        font-style: normal;
        font-weight: 700;
      }
      .framedetailtitlemain::after {
        content: "";
        display: block;
        width: 43px;
        height: 2px;
        margin-top: 7.5%;
        margin-left: 5px;
        background: #fcfcfc;
      }
      .titlecompany {
        width: 500px;
        margin-top: 7%;
        font-style: normal;
        font-weight: 600;
        // font-size: 1vw;
        font-size: 16px;
        margin-left: 5px;
        line-height: 32%;
        // line-height: 170%;
        /* identical to box height, or 160% */
        letter-spacing: -0.02em;
        color: #ffffff;
      }
    }
  }
}
/*PC  WIDTH >=1280px and width < 1535px*/
@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  .frameheaderfoundermain {
    .framemaintilecenterheader {
      font-size: 80px;
      // line-height: 100px;
      line-height: 80px;
    }
  }
}
