@media screen and (min-width: 0) and (max-width: 372px) {
  .divImgSize {
    height: 363px;
  }
}
@media screen and (min-width: 373px) and (max-width: 430px) {
  .divImgSize {
    height: 436px;
  }
}
@media screen and (min-width: 431px) and (max-width: 500px) {
  .divImgSize {
    height: 513px;
  }
}

@media screen and (min-width: 501px) and (max-width: 527px) {
  .divImgSize {
    height: 544px;
  }
}
@media screen and (min-width: 528px) and (max-width: 580px) {
  .divImgSize {
    height: 610px;
  }
}

@media screen and (min-width: 581px) and (max-width: 609px) {
  .divImgSize {
    height: 608px;
  }
}
@media screen and (min-width: 610px) and (max-width: 693px) {
  .divImgSize {
    height: 556px;
  }
}

@media screen and (min-width: 640px) and (max-width: 671px) {
  .divImgSize {
    height: 419px;
  }
}

@media screen and (min-width: 671px) and (max-width: 750px) {
  .divImgSize {
    height: 444px;
  }
}

@media screen and (min-width: 751px) and (max-width: 767px) {
  .divImgSize {
    height: 466px;
  }
}

@media screen and (min-width: 768px) and (max-width: 850px) {
  .divImgSize {
    height: 485px;
  }
}

@media screen and (min-width: 851px) and (max-width: 879px) {
  .divImgSize {
    height: 511px;
  }
}
@media screen and (min-width: 880px) and (max-width: 944px) {
  .divImgSize {
    height: 518px;
  }
}

@media screen and (min-width: 945px) and (max-width: 1023px) {
  .divImgSize {
    height: 592px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .divImgSize {
    height: 450px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .divImgSize {
    height: 560px;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1535px) {
  .divImgSize {
    height: 675px;
  }
}

@media screen and (min-width: 1536px) and (max-width: 1700px) {
  .divImgSize {
    height: 505px;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .divImgSize {
    height: 591px;
  }
}

@media screen and (min-width: 1801px) {
  .divImgSize {
    height: 651px;
  }
}
