#detailpage-banner {
  #logo-cpn {
    width: 192px;
    height: 192px;
    border: solid 1px #d9d9d9;
  }
  .banner {
    padding-top: 80px;
  }

  .banner .banner-line-mobile {
    display: none;
  }

  .banner .banner-title .airkitchen .tag-mobile {
    display: none;
  }

  .banner .banner-title .airkitchen .tag .name-tag .name-tag-1 {
    margin-right: 12px;
  }

  .banner-title {
    padding-top: 103px;
    display: flex;
  }

  .banner-title .airkitchen {
    width: 70%;
  }

  .banner-title .airkitchen .title-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 100px;
    line-height: 100px;
    /* identical to box height, or 100% */

    letter-spacing: -0.01em;

    /* BLACK */

    color: #101010;
  }

  .banner-title .banner-logo {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    margin-right: 76px;
  }

  .banner-logo .logo {
    width: 192px;
    height: 192px;
    border-radius: 50%;
  }

  .airkitchen .tag {
    display: flex;
    align-items: end;
    height: 36px;
    margin-top: 40px;
  }

  .airkitchen .name-tag {
    display: flex;
  }

  .airkitchen .name-tag .name-tag-1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 12px;
    gap: 10px;

    width: 206px;
    height: 36px;

    background: #db2f33;
    border-radius: 4px;
  }

  .airkitchen .name-tag p {
    width: 182px;
    height: 24px;

    font-family: "Roboto Flex";
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    color: #ffffff;
  }

  .airkitchen .name-tag .name-tag-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 12px;
    gap: 10px;

    width: 130px;
    height: 36px;

    background: #db2f33;
    border-radius: 4px;
  }

  .banner-line {
    margin-top: 44px;
    margin-bottom: 60px;
  }

  .banner-line hr {
    width: 43px;
    height: 2px;
    background: #000000;
  }

  /* .banner-table table tr th {

    height: 21px;

    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;


    color: #101010;

    }



    .banner-table table tr td {
        width: 45px;
    height: 21px;

    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;


    color: #101010;
    }

    .banner-table {
        margin-bottom: 60px;
    } */

  .banner-table {
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  .banner-table .column-item-1 {
    padding-right: 100px;
    margin-bottom: 20px;
  }
  .banner-table .column-item-2 {
    // margin-right: 50px;
    padding-right: 100px;
    margin-bottom: 20px;
    max-width: 688px;
  }
  .banner-table .column-item-3 {
    padding-right: 100px;
    margin-bottom: 20px;
  }

  .banner-table .column-item-1 .column-item-name {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    /* BLACK */

    color: #101010;
    margin-bottom: 13px;
  }

  .banner-table .column-item-2 .column-item-name {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    /* BLACK */

    color: #101010;
    margin-bottom: 13px;
  }

  .banner-table .column-item-3 .column-item-name {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    /* BLACK */

    color: #101010;
    margin-bottom: 13px;
  }

  .banner-table .column-item-4 .column-item-name {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    /* BLACK */

    color: #101010;
    margin-bottom: 13px;
  }

  .banner-table .column-item-1 .column-item-value {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    /* BLACK */

    color: #101010;
  }

  .banner-table .column-item-2 .column-item-value {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    /* BLACK */

    color: #101010;
  }

  .banner-table .column-item-3 .column-item-value {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    /* BLACK */

    color: #101010;
  }

  .banner-table .column-item-4 .column-item-value {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    /* BLACK */

    color: #101010;
  }

  /* .banner-table .column-item-1 {
        margin-right: 113px;
    }

    .banner-table .column-item-2 {
        margin-right: 142px;
    }

    .banner-table .column-item-3 {
        margin-right: 153px;
    }

    .banner-table {
        margin-bottom: 60px;
    } */

  /*------------------*/

  .banner-content {
    margin-bottom: 140px;
  }

  .banner-content p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;

    letter-spacing: -0.02em;

    color: #101010;
  }

  .body-line {
    position: absolute;
    width: 100%;
    left: 0;
  }

  // --------------------------------------1023px

  @media only screen and (max-width: 1023px) {
    .banner-title {
      flex-direction: column-reverse;
      padding-top: 0px;
    }

    .banner-title .banner-logo {
      margin-right: 0px;
      display: flex;
      justify-content: start;
    }

    .banner-title .banner-logo .logo {
      width: 80px;
      height: 80px;
    }

    .banner-title .airkitchen {
      width: 80%;
    }

    .banner-title .airkitchen .title-text {
      width: 288px;
      height: 44px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 44px;
      /* identical to box height, or 110% */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #101010;

      margin-top: 20px;
    }

    .airkitchen .tag {
      display: flex;
      align-items: end;
      height: 28px;

      margin-top: 20px;
    }

    .airkitchen .name-tag .name-tag-1 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 6px 12px;
      gap: 10px;

      width: 150px;

      background: #db2f33;
      border-radius: 4px;
      text-align: center;
      margin-bottom: 13px;
    }

    .airkitchen .name-tag .name-tag-1 p {
      font-family: "Roboto Flex";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      color: #ffffff;
    }

    .airkitchen .name-tag .name-tag-2 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 6px 12px;
      gap: 10px;

      width: 98px;
      height: 28px;

      background: #db2f33;
      border-radius: 4px;
      text-align: center;
      margin-right: 12px;
    }

    .airkitchen .name-tag .name-tag-2 p {
      font-family: "Roboto Flex";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      color: #ffffff;
    }

    .banner-title .banner-logo {
      margin-right: 0px;

      /* margin-bottom: 15px; */
    }

    .banner-table {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
      margin-top: 40px;
    }

    .banner-table .column-item-1 {
      padding-right: 38px;
      margin-bottom: 10px;
      margin-right: 12px;
    }

    .banner-table .column-item-2 {
      padding-right: 38px;
      margin-bottom: 10px;
      margin-right: 12px;
    }

    .banner-table .column-item-3 {
      padding-right: 38px;
      margin-right: 12px;
      margin-bottom: 0;
    }

    .banner-table .column-item-1 .column-item-name {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* BLACK */

      color: #101010;
      margin-bottom: 6px;
    }

    .banner-table .column-item-2 .column-item-name {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* BLACK */

      color: #101010;
      margin-bottom: 6px;
    }

    .banner-table .column-item-3 .column-item-name {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* BLACK */

      color: #101010;
      margin-bottom: 6px;
    }

    .banner-table .column-item-4 .column-item-name {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* BLACK */

      color: #101010;
      margin-bottom: 6px;
    }

    .banner-table .column-item-1 .column-item-value {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* BLACK */

      color: #101010;
    }

    .banner-table .column-item-2 .column-item-value {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* BLACK */

      color: #101010;
    }

    .banner-table .column-item-3 .column-item-value {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* BLACK */

      color: #101010;
    }

    .banner-table .column-item-4 .column-item-value {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* BLACK */

      color: #101010;
    }

    .banner .banner-content {
      margin-bottom: 40px;
    }
    .banner .banner-content p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      /* or 171% */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #101010;
    }

    .banner-line {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .banner-line hr {
      width: 43px;
      height: 2px;

      background: #000000;
    }

    .banner .banner-title .airkitchen .tag {
      display: none;
    }

    .banner .banner-title .airkitchen .tag-mobile {
      display: block;
    }

    .banner-title .airkitchen .tag {
      display: none;
    }

    .banner-title .airkitchen .tag-mobile {
      display: flex;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    .banner-title .airkitchen .tag-mobile .name-tag {
      display: flex;
      flex-wrap: wrap;
    }
    .banner-title .airkitchen .tag-mobile .name-tag .name-tag-1 {
      padding: 6px 12px;
      gap: 10px;
      margin-right: 12px;
      width: 150px;
      height: 28px;

      background: #db2f33;
      border-radius: 4px;
    }

    .banner-title .airkitchen .tag-mobile .name-tag .name-tag-3 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 6px 12px;
      gap: 10px;
      width: 98px;
      height: 28px;
      background: #db2f33;
      border-radius: 4px;
      text-align: center;
    }

    .banner-title .airkitchen .tag-mobile .name-tag .name-tag-3 p {
      width: 74px;
      height: 16px;
      font-family: "Roboto Flex";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }

    .banner-line {
      display: none;
    }

    .banner .banner-line-mobile {
      display: block;
      width: 80%;

      margin-top: 40px;
    }

    .banner-line-mobile hr {
      width: 43px;
      height: 2px;

      background: #000000;
    }

    .body-line {
      width: 100%;
      position: unset;
    }

    #logo-cpn {
      width: 80px;
      height: 80px;
      border: solid 1px #d9d9d9;
    }

    #logo-cpn {
      img {
        width: 78px;
        height: 78px;
      }
    }

    #div-tag {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;
      background: #db2f33;
      border-radius: 4px;
      padding: 6px 12px;
    }

    #tag-p {
      font-family: "Roboto Flex";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */
      color: #ffffff;
      padding: 0;
    }

    #tags {
      margin-top: 20px;
      column-gap: 12px;
    }
  }
}
