@media only screen and (max-width: 600px) {
  .ant-tabs-tab {
    width: 82px !important;
    height: 51px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .ant-tabs-tab {
    width: 240px !important;
    font-size: 10px;
  }
  .imgWidth {
    width: 111px !important;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1340px) {
  .ant-tabs-tab {
    width: 240px !important;
    font-size: 10px;
  }
  .imgWidth {
    width: 204px !important;
  }
}
@media screen and (min-width: 640px) and (max-width: 1023px) {
  .ant-tabs-tab {
    width: 136px !important;
    height: 62px !important;
  }
  .imgWidth {
    width: 111px !important;
  }
}

.ant-tabs-content-holder {
  height: auto;
}

.ant-tabs > .ant-tabs-nav {
  margin: 0 auto;
}

#porfolioId:hover {
  #dotParent {
    #dotId {
      background-color: #db2f33 !important;
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 767px) {
  #tabsID {
    margin-top: 28px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .textId {
    height: 360px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .tabletAnim {
    display: block !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .smallTablet {
    display: block !important;
  }
}
@media screen and (min-width: 640px) and (max-width: 767px) {
  .miniTablet {
    display: block !important;
  }
}
