.container-logo-partner {
  margin: auto;
  .column-logo {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 40px;
    height: 400px;
    overflow: hidden;

    .frameimglogo {
      width: 120px;
      height: 70px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .container-logo-partner {
    .column-logo {
      display: grid;
      height: auto !important;
      // overflow: hidden;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      column-gap: 14px;
      row-gap: 20px;
      grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
      // grid-auto-rows: 0; /* set height to 0 for autogenerated grid rows */
      // overflow-y: hidden; /* hide grid items that overflow */
      // grid-auto-flow: row;
      .frameimglogo {
        // width: 100%;
        width: 37px;
        height: 22px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .container-logo-partner {
    margin: auto;
    .framebutton-logopartner {
      max-width: 1450px;
    }
  }
}
