.framemaingallery {
  background-color: #151515;
  position: relative;
  padding-bottom: 18%;
  padding-top: 15%;
  .categorydetailyear {
    display: block;
    // background: "black",
    width: 10%;
    position: sticky;
    top: 50%;
    // transform: translateY(-50%);
    z-index: 5;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
    width: fit-content;
    padding-left: 8%;

    .frameyearcategory {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      color: #ffffff;

      .titleyeardetail:hover {
        cursor: pointer;
        text-decoration-line: underline;
        color: #ffffff;
        opacity: 1;
      }
      .yeartitle {
        color: #ffffff;
        opacity: 0.4;
      }
      .active {
        letter-spacing: 0.02em;
        text-decoration-line: underline;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #ffffff;
        opacity: 1 !important;
      }
    }
  }

  .element {
    // PC 1920
    width: auto;
    // padding-bottom: 18%;
    background-color: #151515;

    .rootgallery {
      width: auto;
      background-color: #151515;
      height: 100%;
      .carousel-gallery {
        position: relative;
        padding-top: 0px;
        color: #ffffff;
        width: 74%;
        height: auto;
        margin: auto;
        // show 4 img
        .frameimgmain4 {
          height: auto;
          .frameimgtop {
            display: flex;
            // flex-direction: row;
            width: 82.5%;
            column-gap: 15%;
            height: auto;
            margin-left: 13.4%;

            .frameimg1 {
              height: auto;

              img {
                width: 100%;
                height: 100%;
                max-width: 581px;
                max-height: 569px;
                object-fit: contain;
              }
            }
            .frameimg2 {
              // 496x269
              // 1366x912 => 390x211
              height: auto;
              margin-top: auto;
              img {
                width: 100%;
                height: 100%;
                max-width: 496px;
                max-height: 569px;
              }
            }
          }
          .frameimgbot {
            width: 82.5%;
            display: flex;
            margin: auto;

            margin-top: 10.5%;
            margin-left: 2%;
            height: 100%;
            column-gap: 15%;
            .frameimg3 {
              height: auto;
              img {
                width: 100%;
                height: 100%;
                max-width: 542px;
                max-height: 569px;
                object-fit: contain;
              }
            }
            .frameimg4 {
              height: auto;
              margin-left: 0px;
              margin-top: 9.5%;

              img {
                width: 100%;
                height: 100%;
                max-width: 519px;
                max-height: 569px;
                object-fit: contain;
              }
            }
          }
        }
        // show 3 img
        .frameimgmain3 {
          height: auto;
          // padding-top: 60px;
          // margin-bottom: 19%;
          .frameimgtop {
            display: flex;
            column-gap: 11%;
            height: auto;
            margin-left: 7%;
            width: 80%;
            .frameimg1 {
              margin-top: 14%;
              img {
                width: 100%;
                height: auto;
                max-width: 496px;
                max-height: 596px;
                object-fit: contain;
              }
            }
            .frameimg2 {
              margin-left: auto;
              img {
                width: 100%;
                height: auto;
                max-width: 475px;
                max-height: 596px;
                object-fit: contain;
              }
            }
          }
          .frameimgbot {
            width: 34%;
            display: flex;
            margin: auto;
            margin-top: 10%;
            height: auto;
            .frameimg3 {
              width: 100%;
              img {
                width: 100%;
                height: 100%;
                max-width: 475px;
                max-height: 596px;
                object-fit: contain;
              }
            }
            .frameimg4 {
              display: none;
            }
          }
        }
        // show 1 img
        .frameimgmain1 {
          // height: auto;
          // padding-top: 60px;
          // margin-bottom: 19%;
          .frameimgtop {
            .frameimg1 {
              margin-top: 0%;
              height: 50vw;
              img {
                margin: auto;
                width: 100%;
                height: 100%;
                max-width: 1296px;
                max-height: 969px;
                object-fit: contain;
              }
            }
            .frameimg2 {
              display: none;
            }
          }
          .frameimgbot {
            display: none;
            .frameimg3 {
              display: none;
            }
            .frameimg4 {
              display: none;
            }
          }
        }
        // show 2 img
        .frameimgmain2 {
          .frameimgtop {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 50px;
            .frameimg1 {
              width: 43%;
              height: 100%;
              margin-left: 2%;
              img {
                width: 100%;
                height: 100%;
                max-width: 596px;
                max-height: 596px;
                object-fit: contain;
              }
            }
            .frameimg2 {
              // max-height: 369px;
              margin-left: auto;
              width: 43%;
              // height: 20.7vw;
              img {
                width: 100%;
                height: 100%;
                max-width: 596px;
                max-height: 596px;
                object-fit: contain;
              }
            }
          }
          .frameimgbot {
            display: none;
            .frameimg3 {
              display: none;
            }
            .frameimg4 {
              display: none;
            }
          }
        }

        .frametitleyear {
          position: absolute;
          left: 50%;
          top: 27%;
          // top: 35%;
          // top: 35%;
          transform: translateX(-50%);
          display: flex;
          flex-direction: column;
          row-gap: 0;
          width: fit-content;
          .titleyeardetail {
            width: auto;
            height: auto;
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 800;
            // font-size: 200px;
            font-size: 10.5vw;
            line-height: 100%;
            /* identical to box height, or 200px */
            text-align: center;
            letter-spacing: 0.02em;
          }
          .titleyeardetail::after {
            color: #fcfcfc;
            content: "";
            display: block;
            width: 43px;
            height: 2px;
            margin: auto;
            margin-top: 5%;
            background: #fcfcfc;
          }
          .detailtitle {
            /* Body 20 Poppins */
            font-family: "Poppins", sans-serif;
            margin: auto;
            margin-top: 5%;
            width: auto;
            font-style: normal;
            font-weight: 400;
            font-size: 1vw;
            // line-height: 32px;
            line-height: 1.7vw;
            /* or 160% */
            text-align: center;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
}

// RESPONSIVE

/* MOBILE WIDTH < 740px */
@media screen and (max-width: 769px) {
  .framemaingallery {
    position: relative;
    padding-top: 0px;
    .categorydetailyear {
      display: block;
      // height: auto;
      width: fit-content;
      position: sticky;
      top: 8%;
      left: 50%;

      transform: translateX(-50%);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding-left: 0%;
      z-index: 2000;
      padding-top: 15px;
      padding-bottom: 70px;
      .frameyearcategory {
        display: flex;
        flex-direction: row;
        column-gap: 25px;

        .titleyeardetail:hover {
          cursor: pointer;
          text-decoration-line: none;
          color: #ffffff;
          opacity: 1;
        }
        .yeartitle {
          color: #ffffff;
          opacity: 0.4;
        }
        .active {
          letter-spacing: 0.02em;
          text-decoration-line: underline;
          font-feature-settings: "tnum" on, "lnum" on;
          color: #ffffff;
          opacity: 1 !important;
        }
      }
    }
    .element {
      // PC 1920
      width: auto;
      .rootgallery {
        width: auto;
        background-color: #151515;
        .carousel-gallery {
          position: relative;
          padding-top: 0px;
          color: #ffffff;
          width: 88%;
          height: auto;
          margin: auto;
          // show 4 img

          .frameimgmain4 {
            height: auto;
            padding-top: 60px;
            padding-bottom: 19%;
            .frameimgtop {
              display: flex;
              width: 90%;
              height: auto;
              margin-left: 9.4%;
              column-gap: 14%;

              .frameimg1 {
                width: 38%;
                img {
                  width: 100%;
                  height: 100%;
                  max-width: 232px;
                  max-height: 138px;
                  object-fit: contain;
                }
              }
              .frameimg2 {
                // height: auto;
                margin-top: auto;
                // width: 30vw;
                // width: 28%;
                width: 35%;

                img {
                  width: 100%;
                  height: 100%;
                  max-width: 198px;
                  max-height: 138px;
                  object-fit: contain;
                }
              }
            }
            .frameimgbot {
              width: 100%;
              display: flex;
              margin-top: 10.5%;
              margin-left: 0px;
              height: auto;
              column-gap: 12%;
              .frameimg3 {
                // width: 70%;
                width: 39%;
                // height: 19vw;
                img {
                  width: 100%;
                  height: 100%;
                  max-width: 220px;
                  max-height: 138px;
                  object-fit: contain;
                  // 220 146
                }
              }
              .frameimg4 {
                width: 37%;
                margin-left: 0px;
                // margin-top: 9vw;
                // height: 16vw;
                img {
                  // 210 118
                  margin-top: 8px;
                  width: 100%;
                  height: 100%;
                  max-width: 210px;
                  max-height: 138px;
                  object-fit: contain;
                }
              }
            }
          }
          .frameimgmain3 {
            height: auto;
            // padding-top: 60px;
            // margin-bottom: 19%;
            .frameimgtop {
              display: flex;
              column-gap: 11%;
              height: auto;
              margin-left: 7%;
              .frameimg1 {
                width: 70%;
                margin-top: 70px;

                img {
                  width: 100%;
                  height: 100%;
                  max-width: 244px;
                  max-height: 138px;
                  object-fit: contain;
                }
              }
              .frameimg2 {
                width: 70%;
                margin-left: 0px;
                // height: 19vw;
                // max-height: 136px;
                img {
                  // 234 x156
                  width: 100%;
                  height: 100%;
                  max-width: 244px;
                  max-height: 138px;
                  object-fit: contain;
                }
              }
            }
            .frameimgbot {
              width: 36%;
              display: flex;
              margin: auto;
              margin-top: 10%;
              height: auto;
              .frameimg3 {
                img {
                  width: 100%;
                  height: 100%;
                  max-width: 208px;
                  max-height: 138px;
                  object-fit: contain;
                }
              }
              .frameimg4 {
                display: none;
              }
            }
          }

          .frameimgmain1 {
            // height: auto;
            // padding-top: 60px;
            // margin-bottom: 19%;
            .frameimgtop {
              .frameimg1 {
                margin-top: 0%;
                img {
                  margin: auto;
                  width: 100%;
                  height: 100%;
                  max-width: 578px;
                  max-height: 387px;
                  object-fit: contain;
                }
              }
              .frameimg2 {
                display: none;
              }
            }
            .frameimgbot {
              display: none;
              .frameimg3 {
                display: none;
              }
              .frameimg4 {
                display: none;
              }
            }
          }
          .frameimgmain2 {
            .frameimgtop {
              width: 100%;

              display: flex;
              flex-direction: column;
              row-gap: 40px;

              .frameimg1 {
                width: 50%;
                img {
                  width: 100%;
                  height: 100%;
                  max-width: 244px;
                  max-height: 138px;
                  object-fit: contain;
                }
              }
              .frameimg2 {
                // height: 18vw;
                // max-height: 369px;
                width: 50%;
                margin-left: auto;
                // height: 20.7vw;
                img {
                  margin-left: auto;
                  width: 100%;
                  height: 100%;
                  max-width: 244px;
                  max-height: 138px;
                  object-fit: contain;
                }
              }
            }
            .frameimgbot {
              display: none;
              .frameimg3 {
                display: none;
              }
              .frameimg4 {
                display: none;
              }
            }
          }

          .frametitleyear {
            position: absolute;
            left: 50%;
            top: 27%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            row-gap: 0;
            width: fit-content;
            .titleyeardetail {
              width: auto;
              height: auto;
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 800;
              // font-size: 200px;
              font-size: 60px;
              line-height: 100%;
              /* identical to box height, or 200px */
              text-align: center;
              letter-spacing: 0.02em;
            }
            .titleyeardetail::after {
              color: #fcfcfc;
              content: "";
              display: block;
              width: 43px;
              height: 2px;
              margin: auto;
              margin-top: 8%;
              background: #fcfcfc;
            }
            .detailtitle {
              /* Body 20 Poppins */
              font-family: "Poppins", sans-serif;
              margin: auto;
              margin-top: 7%;
              width: 288px;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              /* or 160% */
              text-align: center;
              letter-spacing: -0.02em;
            }
          }
        }
      }
    }
  }
}

/* MOBILE WIDTH < 740px */
/* TABLET WIDTH >=770px and width <  1279px*/
@media only screen and (min-width: 770px) and (max-width: 1279px) {
  .framemaingallery {
    .categorydetailyear {
      padding-left: 5%;
    }
  }
}
